@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~react-toastify/dist/ReactToastify.css';
@import url("https://cdn.syncfusion.com/ej2/bootstrap4.css");

@import './colors.css';


.br_pluginconnection_mtr {
    margin-top: 1rem;
}

.br_pluginconnection_half_mtr {
    margin-top: 0.5rem;
}

.br_pluginconnection_coi {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--busy--color);
    float: right;
}

.br_pluginconnection_halfbold {
    font-weight: 500;
}

.br_pluginconnection_ColumnButton {
    background-color: transparent;
    color: black;
    border-color: black;
}

.br_pluginconnection_amountTitle {
    margin-right: 0.5rem;
}

.br_pluginconnection_saveIcon {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.br_pluginconnection_closeIcon {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.br_pluginconnection_downloadIcon {
    cursor: pointer;
    color: var(--primary--color);
    font-size: 20px;
}

.br_pluginconnection_modalWidth {
    max-width: 80%;
}

.br_pluginconnection_exportCard {
    font-size: 0.7rem;
}